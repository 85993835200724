import React from 'react'

import Layout from '@components/layout/Layout'
import NarcolepsyFaqComponent from '@components/faqs/faq-glossary/faq-glossary.component'


const NarcolepsyFaqPage = ({ location }) => {
  return (
    <Layout location={location}>
      <NarcolepsyFaqComponent />
    </Layout>
  )
}

export default NarcolepsyFaqPage
